<template>
  <div class="table-component">
    <template v-if="data.ok && loadingState !== 'loading'">
      <!-- NAV -->
      <VacationTableNavigation
        v-if="data.type === 'common'"
        :months="data.calendar.productionCalendar"
        :editedMonths="editedMonths"
        :active="activeMonth"
        @click="scrollTo"
      />

      <!-- TABLE -->
      <div
        class="table-wrapper modal-scroll"
        ref="scrolableWrapperRef"
        @scroll="onScroll"
      >
        <table
          ref="table"
          class="table-vacation"
          :class="{
            'table--loading': loadingState === 'update',
            'table--selected': isCellsSelected,
          }"
          @mousedown="mousedownHandler"
          @mouseup="mouseupHandler"
          @mouseover="overHandler"
          @mouseleave="leaveHandler"
          @contextmenu="contextmenuHandler"
        >
          <thead>
            <tr>
              <th></th>
              <th
                v-for="month of data.calendar.productionCalendar"
                :key="month.name"
              >
                <div :ref="month.name" class="header__month-name">
                  {{ month.name }}
                </div>
                <div class="month-days-list">
                  <div
                    v-for="(day, dayIndex) of month.days"
                    :key="`${day.number}:${day.id}:${month.name}:${dayIndex}`"
                    class="month-day"
                    :class="
                      kindOfDay(
                        data.calendar.getDayType(month.number, day.number)
                      )
                    "
                  >
                    <div class="month-day-of-week">
                      {{ day.dayOfWeek }}
                    </div>
                    <div class="month-day-name">{{ day.number }}</div>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody
            v-for="group of data.groups"
            :key="group.id"
            @dragstart="(e) => e.preventDefault()"
          >
            <tr v-if="data.type === 'context'">
              <td class="project-border project-border--name" colspan="2">
                {{ group.name }}
              </td>
            </tr>
            <tr
              v-for="employee of group.staff"
              :key="`${employee.id}::${group.id}`"
            >
              <td
                class="fixed-cell"
                :class="{
                  'fixed-cell--myself': me.id === employee.id,
                  'fixed-cell--selected': editedEmployeeId === employee.id,
                }"
                @click="selectEmployee(employee.id)"
              >
                <Tooltip
                  :tooltipData="fullName(employee)"
                  :tooltip-background="
                    editedEmployeeId === employee.id ? '#6fa8dc' : '#263238'
                  "
                  :classNames="{ 'expenses__body-cell-data--text': true }"
                />
              </td>
              <td
                v-for="(month, monthIdx) of data.calendar.productionCalendar"
                :key="month.name + month.id"
                :class="{
                  'row--selected': editedEmployeeId === employee.id,
                  'row--myself': me.id === employee.id,
                  'month--disabled': !data.calendar.isMonthEditable(
                    employee.id,
                    monthIdx
                  ),
                  'month--haze':
                    !editedMonths.includes(monthIdx) &&
                    activeMonth !== month.name,
                }"
                @click="selectEmployee(employee.id)"
              >
                <div class="month-days-list">
                  <div
                    v-for="(day, dayIdx) of month.days"
                    :key="`${day.id}:${employee.id}:${dayIdx}`"
                    :data-index-path="`${employee.id}::${monthIdx}::${dayIdx}`"
                    class="month-day"
                    :class="{
                      'month-day--selected': selectedDates.includes(
                        `${employee.id}::${monthIdx}::${dayIdx}`
                      ),
                      ...kindOfDay(
                        data.calendar.getDayType(
                          month.number,
                          day.number,
                          employee.id
                        )
                      ),
                    }"
                  ></div>
                </div>
              </td>
            </tr>
            <tr v-if="data.type === 'context'">
              <td class="project-border project-border--end" colspan="2"></td>
            </tr>
          </tbody>
        </table>
      </div>
      <VacationModal ref="modal" @selectDayType="selectDayType" />
    </template>
    <Loading v-else-if="loadingState === 'loading'" />
    <TooltipCloud />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { debounce, throttle } from "lodash";
import { retrier } from "@/assets/utils";
import VacationModal from "@/components/modal/VacationModal.vue";
import VacationTableNavigation from "@/components/VacationTableNavigation.vue";
import Loading from "@/components/Loading.vue";
import Tooltip from "@/components/expenses/TooltipBody";
import TooltipCloud from "@/components/expenses/TooltipCloud";

export default {
  name: "VacationTable",
  components: {
    VacationTableNavigation,
    VacationModal,
    Loading,
    Tooltip,
    TooltipCloud,
  },
  props: {
    data: { type: Object }, // TODO: давай переименуем, чтобы понятно было, что за дата
    loadingState: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isCellsSelected: false,
      leftEdge: null,
      rightEdge: null,
      activeMonth: null,
      selectedDates: [],
      editedEmployeeId: null,
      editedMonths: [],
      isScrollDone: true,
      lastScrollLeftPosition: 0,
      tableCenter: 0,
    };
  },
  mounted() {
    window.addEventListener("resize", this.calcResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.calcResize);
  },
  watch: {
    loadingState: {
      handler(state, prevState) {
        if (state === "completed" && prevState !== "update") {
          this.editedEmployeeId = null;
          this.scrollTo(this.data.currentMonthId);
          this.$nextTick(() => {
            this.calcTableCenter();
          });
        }
      },
      immediate: true,
    },
    data: {
      handler() {
        this.changeStorageId(null, "clear");
      },
      deep: true,
    },
    "data.currentMonthId": {
      handler(id) {
        this.scrollTo(id);
      },
    },
    "data.calendar.displayedMonthsCount": {
      handler() {
        this.scrollTo(this.data.currentMonthId);
      },
    },
    selectedDates(dates) {
      this.setEditedMonths(dates);
    },
  },
  computed: {
    ...mapState({
      me: (state) => state.userStore.me,
    }),
  },
  methods: {
    hasShiftX(xPosition) {
      const isTrue = xPosition !== this.lastScrollLeftPosition;
      this.lastScrollLeftPosition = xPosition;
      return isTrue;
    },
    scrollTo(id) {
      this.$nextTick(() => {
        const target = this.$refs[id]?.[0] || {};
        const isDomElem = target instanceof Element;
        if (!isDomElem) {
          return;
        }
        const observer = new IntersectionObserver((entries, observer) => {
          if (entries[0].isIntersecting) {
            observer.disconnect();
            setTimeout(() => {
              this.isScrollDone = true;
            }, 800);
          }
        });

        observer.observe(target);
        target.scrollIntoView({
          behavior: "smooth",
          inline: "center",
          block: "nearest",
        });

        this.isScrollDone = false;
        this.activeMonth = id;
      });
    },
    fullName(person) {
      return `${person.last_name} ${person.first_name}`;
    },
    kindOfDay(dateType) {
      return {
        "month-day--sick": dateType === "SICK",
        "month-day--vacation": dateType === "VACATION",
        "month-day--dayoff": dateType === "DAY_OFF",
        "month-day--shortened": dateType === "SHORTENED",
      };
    },
    mousedownHandler(event) {
      const dataIndexPath = event.target.getAttribute("data-index-path");
      const mouseButton = event.button;
      // прерываем если у ячейки нет атрибута data-index-path
      // или нажата недопустимая кнопка мыши
      if (!dataIndexPath || ![0, 2].includes(mouseButton)) {
        return;
      }
      const [employeeId, monthIdx, dayIdx] = this.getDatePath(dataIndexPath);

      this.editedEmployeeId = employeeId;

      const isMonthEditable = this.data.calendar.isMonthEditable(
        employeeId,
        monthIdx
      );

      // прерываем если у нас нет прав на редактирование пользователя
      // или если месяц нередактируемый
      if (!isMonthEditable) {
        return;
      }
      // обрабатываем нажатие правой кнопки мыши
      if (mouseButton === 2) {
        if (!this.selectedDates.length) {
          this.changeStorageId(null, "clear");
          this.changeStorageId(dataIndexPath, "add");
        }
        return;
      }
      this.isCellsSelected = true;
      this.leftEdge = [employeeId, monthIdx, dayIdx];
      if (event.ctrlKey || event.metaKey) {
        if (this.selectedDates.length) {
          const [addedEmployeeIdx] = this.getDatePath(this.selectedDates[0]);
          if (addedEmployeeIdx !== employeeId) {
            this.changeStorageId(null, "clear");
          }
        }
        this.changeStorageId(dataIndexPath);
      } else {
        const isAdded = this.selectedDates.includes(dataIndexPath);
        this.changeStorageId(null, "clear");
        if (!isAdded) {
          this.changeStorageId(dataIndexPath);
        }
      }
    },
    mouseupHandler(event) {
      event.preventDefault();
      this.isCellsSelected = false;
      this.leftEdge = null;
      this.rightEdge = null;
    },
    overHandler(event) {
      const dataIndexPath = event.target.getAttribute("data-index-path");
      if (!dataIndexPath || !this.leftEdge) {
        return;
      }
      const [startEmployeeIdx, startMonthIdx, startDayIdx] = this.leftEdge;
      const [endEmployeeIdx, endMonthIdx, endDayIdx] =
        this.getDatePath(dataIndexPath);

      if (!this.isCellsSelected || startEmployeeIdx !== endEmployeeIdx) {
        return;
      }
      if (
        (startMonthIdx === endMonthIdx && startDayIdx >= endDayIdx) ||
        startMonthIdx > endMonthIdx
      ) {
        this.leftEdge = [endEmployeeIdx, endMonthIdx, endDayIdx];
        this.rightEdge = [startEmployeeIdx, startMonthIdx, startDayIdx];
      } else {
        this.rightEdge = [endEmployeeIdx, endMonthIdx, endDayIdx];
      }
      this.pointsConnect();
    },
    leaveHandler() {
      this.isCellsSelected = false;
    },
    pointsConnect() {
      const [startEmployeeId, startMonthIdx, startDayIdx] = this.leftEdge;
      const [_endEmployeeId, endMonthIdx, endDayIdx] = this.rightEdge;
      let inProgress = true; // выключатель для while
      let currentMonthIdx = startMonthIdx; // индекс месяца, который обрабатывается в while
      let currentDayIdx = startDayIdx; // индекс дня, который обрабатывается в while
      let lastIdxCurrentMonth =
        this.data.calendar.productionCalendar.length - 1; // крайний индекс месяцев в текущем году
      let lastIdxCurrentDay =
        this.data.calendar.productionCalendar[currentMonthIdx].days.length - 1; // крайний индекс дней в текущем месяце
      let isActiveMonth = true;
      let countedMonthIdx = null;
      while (inProgress) {
        // если индекс текущего месяца больше или равен индексу завершающего месяца
        // или индексы месяцев равны, но индекс текущего дня больше индекса завершающего дня,
        // или достигли доступного конца периода, тогда задача выполнена и завершаем процесс
        if (
          currentMonthIdx > endMonthIdx ||
          (currentMonthIdx === endMonthIdx && currentDayIdx > endDayIdx) ||
          (currentMonthIdx === lastIdxCurrentMonth &&
            currentDayIdx > lastIdxCurrentDay)
        ) {
          inProgress = false;
          return;
        }
        // доходя до последнего дня месяца переходим в новый месяц
        if (currentDayIdx > lastIdxCurrentDay) {
          currentMonthIdx += 1;
          lastIdxCurrentDay =
            this.data.calendar.productionCalendar[currentMonthIdx].days.length -
            1;
          currentDayIdx = 0;
        }

        if (countedMonthIdx !== currentMonthIdx) {
          isActiveMonth = this.data.calendar.isMonthEditable(
            startEmployeeId,
            currentMonthIdx
          );

          countedMonthIdx = currentMonthIdx;
        }

        if (isActiveMonth) {
          this.changeStorageId(
            `${startEmployeeId}::${currentMonthIdx}::${currentDayIdx}`,
            "add"
          );
        }

        currentDayIdx += 1;
      }
    },
    changeStorageId(id, mode = "switch") {
      const idx = this.selectedDates.findIndex((storeId) => storeId === id);
      if (mode === "switch") {
        if (idx > -1) {
          this.selectedDates.splice(idx, 1);
        } else {
          this.selectedDates.push(id);
        }
      } else if (mode === "remove") {
        if (idx > -1) {
          this.selectedDates.splice(idx, 1);
        }
      } else if (mode === "add") {
        if (idx === -1) {
          this.selectedDates.push(id);
        }
      } else if (mode === "clear") {
        this.selectedDates = [];
      }
    },
    contextmenuHandler(event) {
      event.preventDefault();
      if (!this.selectedDates.length) {
        return;
      }
      this.$refs.modal.open();
      this.$refs.modal.setPosition({
        top: event.clientY,
        left: event.clientX,
      });
    },
    selectDayType(type) {
      this.selectedDates.sort((a, b) => {
        const [_aEmployeeId, aMonthIdx, aDayIdx] = this.getDatePath(a || "");
        const [_bEmployeeId, bMonthIdx, bDayIdx] = this.getDatePath(b || "");
        return aMonthIdx - bMonthIdx || aDayIdx - bDayIdx;
      });
      const { intervals } = this.selectedDates.reduce(
        (state, currentIdxPath, currentIteration, { length }) => {
          // TODO: убираем здесь state и придумываем понятное наименование.
          const prevId = state.intervals[state.index]?.at(-1) || ""; // получаем последний добавленный idxPath
          const [_prevEmployeeIdx, prevMonthIdx, prevDayIdx] =
            this.getDatePath(prevId);
          const [_curEmployeeIdx, curMonthIdx, curDayIdx] =
            this.getDatePath(currentIdxPath);
          const prevMonthDaysMaxIdx =
            this.data.calendar.productionCalendar[prevMonthIdx]?.days.length -
            1; // получаем индекс последнего дня предыдущего idxPath
          if (
            prevId &&
            ((curMonthIdx === prevMonthIdx && curDayIdx !== prevDayIdx + 1) ||
              (curMonthIdx === prevMonthIdx + 1 &&
                (curDayIdx > 0 ||
                  (curDayIdx === 0 && prevDayIdx !== prevMonthDaysMaxIdx))))
          ) {
            // переключаем на новый интервал дат
            state.index += 1;
          }
          // создаем контейнер нового интервала если его нет
          if (!state.intervals[state.index]) {
            state.intervals.push([]);
          }
          state.intervals[state.index].push(currentIdxPath);
          // если последняя итерация, то преобразуем idxPath's хранящиеся в intervals, в id дней
          if (currentIteration === length - 1) {
            state.intervals = state.intervals.map((datesInterval) => {
              return datesInterval.map((idxPath) => {
                const [_employeeId, monthIdx, dayIdx] =
                  this.getDatePath(idxPath);
                const realMonthIdx =
                  this.data.calendar._edgesForDisplayedMonths.start + monthIdx;
                const dayId = this.data.calendar.getDayId(realMonthIdx, dayIdx);
                return [dayId, `${_employeeId}::${realMonthIdx}::${dayIdx}`];
              });
            });
          }
          return state;
        },
        { index: 0, intervals: [] }
      );
      this.$emit("changeDates", intervals, type, this.editedEmployeeId);
      this.changeStorageId(null, "clear");
    },
    selectEmployee(id) {
      this.editedEmployeeId = id;
    },
    setEditedMonths(dates) {
      this.editedMonths = [];
      const set = new Set();
      for (const date of dates) {
        const monthIdx = parseInt(date.split("::")[1]);
        set.add(monthIdx);
      }
      this.editedMonths = [...set];
    },
    getDatePath(path) {
      return path.split("::").map((x) => parseInt(x, 10));
    },
    onScroll: throttle(function () {
      if (!this.isScrollDone) {
        return;
      }
      let activeMonthId = null;

      const isBorder = this.isNearBorder();
      if (isBorder.success) {
        const monthIndex = isBorder.side === "left" ? 0 : -1;
        activeMonthId =
          this.data.calendar.productionCalendar.at(monthIndex).name;
      } else {
        activeMonthId = this.getActiveMonths();
      }
      this.activeMonth = activeMonthId;
    }, 500),
    getActiveMonths() {
      this.calcTableCenter();
      let activeMonthId = null;
      const containerCenter = this.tableCenter;
      for (const month of this.data.calendar.productionCalendar) {
        const { name: monthId } = month;
        if (!this.$refs[monthId]) {
          continue;
        }
        const { left: monthRectLeft = null, right: monthRectRight = null } =
          this.$refs[monthId]?.[0]?.getBoundingClientRect() || {};
        if (
          monthRectLeft < containerCenter &&
          containerCenter < monthRectRight
        ) {
          activeMonthId = monthId;
          break;
        }
      }

      return activeMonthId;
    },
    isNearBorder() {
      const result = { success: false, side: null };
      const bufferSize = 20;
      const abs = Math.abs;
      const container = this.$refs.scrolableWrapperRef;
      const table = this.$refs.table;
      if (!container || !table) {
        return result;
      }
      const { left: tableLeft, right: tableRight } =
        table.getBoundingClientRect();
      const { left: containerLeft, right: containerRight } =
        container.getBoundingClientRect();

      if (abs(tableLeft - containerLeft) < bufferSize) {
        result.side = "left";
        result.success = true;
      } else if (abs(tableRight - containerRight) < bufferSize) {
        result.side = "right";
        result.success = true;
      }
      return result;
    },
    async calcTableCenter(isRestart = false) {
      const employeeNameCell = document.querySelector(".fixed-cell");
      const isDomElem = employeeNameCell instanceof Element;

      if (!isDomElem) {
        if (!isRestart) {
          const restartThisMethod = retrier(this.calcTableCenter, {
            delay: 1000,
            maxRetries: 5,
          });
          await restartThisMethod(true);
        }
        return;
      }

      const employeeCellWidth = parseInt(
        window.getComputedStyle(employeeNameCell).getPropertyValue("width")
      );

      const tableCenter =
        (this.$refs.scrolableWrapperRef?.offsetWidth + employeeCellWidth) / 2 +
        employeeCellWidth;
      this.tableCenter = tableCenter;
    },
    calcResize: debounce(function () {
      this.calcTableCenter();
    }, 500),
  },
};
</script>

<style lang="scss" scope>
@import "@/assets/css/variables";

.table-nav {
  display: flex;
  width: 100%;
  max-width: calc(100vw - 230px);
  gap: 1px;
}

.table-nav__item {
  border-radius: 5px 5px 0 0;
  border: 1px solid $erp-primary-bg-color;
  padding: 2px 4px;
  background-color: $erp-primary-bg-color;
  color: $erp-light-color;
  cursor: pointer;
  transition: 300ms background-color color;
  width: calc(100% / 12);
  text-transform: capitalize;

  &--active {
    color: $erp-dark-color;
    background-color: $erp-light-color;
    font-weight: 600;
    transition: 300ms background-color color;

    &.table-nav__item--edited {
      background-color: #eaf6ff;
    }
  }

  &--edited {
    background-color: #597f9a;
  }
}

.table-wrapper {
  width: min-content;
  max-width: calc(100vw - 230px);
  height: 100%;
  max-height: 70vh;
  overflow-x: auto;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
}

.table-vacation {
  position: relative;
  border-spacing: 0px;
  table-layout: fixed;
  width: max-content;

  &--selected {
    cursor: move;
  }

  &--loading {
    cursor: wait;
    user-select: none;

    &::before {
      z-index: 1;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(151, 210, 238, 0.1);
      user-select: none;
    }
  }

  th,
  td {
    border: 1px solid #a4a4a4;
    border-left: none;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: $erp-secondary-bg-color;
    user-select: none;
  }

  th:first-child,
  td:first-child {
    position: sticky;
    will-change: transform;
    left: 0;
    z-index: 2;
    max-width: 250px;
    width: 100%;
    background-color: $erp-primary-bg-color;
    border: 1px solid $erp-light-color;
    color: $erp-secondary-bg-color;
  }
}

.fixed-cell {
  user-select: none;
  padding-left: 15px;

  &--myself {
    background: linear-gradient(#1981c9, #1981c9);
  }

  &--selected {
    background: linear-gradient(#6fa8dc, #6fa8dc);
  }
}

td.row--myself {
  border-top: 2px solid #1981c9;
  border-bottom: 2px solid #1981c9;
}

td.row--selected {
  border-top: 2px solid #6fa8dc;
  border-bottom: 2px solid #6fa8dc;
}

.month {
  &--disabled {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  &--haze {
    background-color: rgba(149, 9, 137, 0.1);
  }

  &--disabled {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.month-days-list {
  display: flex;
  min-height: 20px;
}

.month-day {
  width: 25px;

  &:not(:last-child) {
    border-right: 1px solid $erp-primary-bg-color;
  }

  &--sick {
    background-color: #80cfc8;
  }

  &--vacation {
    background-color: #a1d881;
  }

  &--dayoff {
    background-color: #ffba9b;
  }

  &--shortened {
    background-color: #fdff8a;
  }

  &--selected {
    background-color: #c6dcfa;
  }
}

.project-border {
  height: 25px;
  text-align: center;
  font-weight: 600;

  &--end {
    background-color: $erp-secondary-bg-color !important;
    height: 30px;
  }
}

.fixed-cell__tooltip-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.fixed-cell__tooltip {
  width: max-content;
  border-radius: 6px;
  border: 1px solid #b9b9b9;
  background: #ffffff;
  color: #2c3e50;
  font-weight: 400;
  padding: 4px;
}
</style>
