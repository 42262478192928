<template>
  <IntervalSelect
    ref="interval-select"
    :initDate="initDate"
    :lastPlanningDate="lastPlanningDate"
    :firstPlanningDate="firstPlanningDate"
    :availableModes="modes"
    :datesMap="datesMap"
    storageKey="_interval-mode-vacation"
    @getInterval="getInterval"
    @changeMode="changeMode"
  />
</template>

<script>
import IntervalSelect from "@/components/ui/IntervalSelect.vue";

export default {
  name: "VacationIntervalSelect",
  components: {
    IntervalSelect,
  },
  props: {
    lastPlanningDate: {
      type: String,
      default: "",
    },
    firstPlanningDate: {
      type: String,
      default: "",
    },
    initDate: {
      default: null,
    },
    datesMap: {
      type: Array,
    },
    modes: {
      type: Array,
    },
  },
  methods: {
    getInterval(interval) {
      this.$emit("getInterval", interval);
    },
    changeMode(mode, prevMode) {
      this.$emit("changeMode", mode, prevMode);
    },
    async selectMode(mode) {
      await this.$refs["interval-select"].selectMode(mode);
    },
  },
};
</script>
