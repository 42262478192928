var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-component"},[(_vm.data.ok && _vm.loadingState !== 'loading')?[(_vm.data.type === 'common')?_c('VacationTableNavigation',{attrs:{"months":_vm.data.calendar.productionCalendar,"editedMonths":_vm.editedMonths,"active":_vm.activeMonth},on:{"click":_vm.scrollTo}}):_vm._e(),_c('div',{ref:"scrolableWrapperRef",staticClass:"table-wrapper modal-scroll",on:{"scroll":_vm.onScroll}},[_c('table',{ref:"table",staticClass:"table-vacation",class:{
          'table--loading': _vm.loadingState === 'update',
          'table--selected': _vm.isCellsSelected,
        },on:{"mousedown":_vm.mousedownHandler,"mouseup":_vm.mouseupHandler,"mouseover":_vm.overHandler,"mouseleave":_vm.leaveHandler,"contextmenu":_vm.contextmenuHandler}},[_c('thead',[_c('tr',[_c('th'),_vm._l((_vm.data.calendar.productionCalendar),function(month){return _c('th',{key:month.name},[_c('div',{ref:month.name,refInFor:true,staticClass:"header__month-name"},[_vm._v(" "+_vm._s(month.name)+" ")]),_c('div',{staticClass:"month-days-list"},_vm._l((month.days),function(day,dayIndex){return _c('div',{key:`${day.number}:${day.id}:${month.name}:${dayIndex}`,staticClass:"month-day",class:_vm.kindOfDay(
                      _vm.data.calendar.getDayType(month.number, day.number)
                    )},[_c('div',{staticClass:"month-day-of-week"},[_vm._v(" "+_vm._s(day.dayOfWeek)+" ")]),_c('div',{staticClass:"month-day-name"},[_vm._v(_vm._s(day.number))])])}),0)])})],2)]),_vm._l((_vm.data.groups),function(group){return _c('tbody',{key:group.id,on:{"dragstart":(e) => e.preventDefault()}},[(_vm.data.type === 'context')?_c('tr',[_c('td',{staticClass:"project-border project-border--name",attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(group.name)+" ")])]):_vm._e(),_vm._l((group.staff),function(employee){return _c('tr',{key:`${employee.id}::${group.id}`},[_c('td',{staticClass:"fixed-cell",class:{
                'fixed-cell--myself': _vm.me.id === employee.id,
                'fixed-cell--selected': _vm.editedEmployeeId === employee.id,
              },on:{"click":function($event){return _vm.selectEmployee(employee.id)}}},[_c('Tooltip',{attrs:{"tooltipData":_vm.fullName(employee),"tooltip-background":_vm.editedEmployeeId === employee.id ? '#6fa8dc' : '#263238',"classNames":{ 'expenses__body-cell-data--text': true }}})],1),_vm._l((_vm.data.calendar.productionCalendar),function(month,monthIdx){return _c('td',{key:month.name + month.id,class:{
                'row--selected': _vm.editedEmployeeId === employee.id,
                'row--myself': _vm.me.id === employee.id,
                'month--disabled': !_vm.data.calendar.isMonthEditable(
                  employee.id,
                  monthIdx
                ),
                'month--haze':
                  !_vm.editedMonths.includes(monthIdx) &&
                  _vm.activeMonth !== month.name,
              },on:{"click":function($event){return _vm.selectEmployee(employee.id)}}},[_c('div',{staticClass:"month-days-list"},_vm._l((month.days),function(day,dayIdx){return _c('div',{key:`${day.id}:${employee.id}:${dayIdx}`,staticClass:"month-day",class:{
                    'month-day--selected': _vm.selectedDates.includes(
                      `${employee.id}::${monthIdx}::${dayIdx}`
                    ),
                    ..._vm.kindOfDay(
                      _vm.data.calendar.getDayType(
                        month.number,
                        day.number,
                        employee.id
                      )
                    ),
                  },attrs:{"data-index-path":`${employee.id}::${monthIdx}::${dayIdx}`}})}),0)])})],2)}),(_vm.data.type === 'context')?_c('tr',[_c('td',{staticClass:"project-border project-border--end",attrs:{"colspan":"2"}})]):_vm._e()],2)})],2)]),_c('VacationModal',{ref:"modal",on:{"selectDayType":_vm.selectDayType}})]:(_vm.loadingState === 'loading')?_c('Loading'):_vm._e(),_c('TooltipCloud')],2)
}
var staticRenderFns = []

export { render, staticRenderFns }