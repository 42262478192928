<template>
  <div
    class="filter"
    :class="{ 'filter--active': withActiveItemsNumber }"
    v-click-outside="closeModal"
  >
    <div class="filter-title" @click="toggleModal">
      {{ title }}
      <div v-if="withActiveItemsNumber" class="filter-title__active-count">
        {{ selectedValuesLocal.length }}
      </div>
    </div>
    <div v-show="isOpen && list.length" class="filter-body">
      <div class="filter-body__search-wrapper" v-if="withSearch">
        <CommonSearch
          ref="search"
          placeholder="Поиск"
          :autofocus="true"
          :isOpen="isOpen"
          @inputValue="searchValue"
        />
      </div>
      <div class="filter-body__item filter-body__item--wrapper">
        <Button theme="label" @click="selectAll">all</Button>
        <Button theme="label" @click="removeSelection">none</Button>
        <Button
          v-if="withApproveButton"
          theme="label"
          color="accept"
          @click="approve"
          class="filter-body__item-approve"
          >Ok</Button
        >
      </div>
      <div class="filter-body__scroll modal-scroll">
        <div
          v-for="item of filteredList"
          :key="'filter-' + item.id"
          class="filter-body__item"
          :class="{ 'filter-body__item--capitalize': withCapitalize }"
        >
          <label class="filter-body__item-label"
            ><input
              :value="item.id"
              v-model="selectedValuesLocal"
              type="checkbox"
              @change="userCheck"
            /><span>{{ item.name }}</span></label
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button.vue";
import CommonSearch from "@/components/CommonSearch.vue";

export default {
  name: "CheckboxFilterModal",
  components: {
    Button,
    CommonSearch,
  },
  model: {
    prop: "selectedValues",
    event: "setValue",
  },
  props: {
    title: {
      type: String,
      default: "Unset",
    },
    list: {
      type: Array,
      default: () => [],
    },
    selectedValues: {
      type: Array,
      default: () => [],
    },
    withCapitalize: {
      type: Boolean,
      default: false,
    },
    withActiveItemsNumber: {
      type: Boolean,
      default: false,
    },
    withSearch: {
      type: Boolean,
      default: false,
    },
    withApproveButton: {
      type: Boolean,
      default: false,
    },
    allSetAtInitialization: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedValuesLocal: [],
      prevSelectedValuesState: [],
      search: "",
      isOpen: false,
      isApproved: false,
      isAllSelected: false,
    };
  },
  created() {
    if (this.allSetAtInitialization) {
      this.selectedValuesLocal = this.list.map((option) => option.id);
    }
  },
  watch: {
    selectedValues: {
      handler(list) {
        this.selectedValuesLocal = list;
        if (list.length !== 0 && list.length === this.list.length) {
          this.isAllSelected = true;
        } else {
          this.isAllSelected = false;
        }
      },
      immediate: true,
    },
    list: {
      handler(data) {
        const newListIds = data.map((option) => option.id);
        if (this.isAllSelected || this.allSetAtInitialization) {
          this.selectedValuesLocal = newListIds;
        } else {
          this.selectedValuesLocal = this.selectedValuesLocal.filter((id) =>
            newListIds.includes(id)
          );
        }
      },
      deep: true,
    },
    isOpen(isTrue) {
      if (isTrue) {
        this.$emit("open");
      } else {
        this.$emit("close", this.isApproved);
      }
      if (!this.withApproveButton) {
        return;
      }
      if (isTrue) {
        this.prevSelectedValuesState = this.selectedValuesLocal;
      } else if (!isTrue && !this.isApproved) {
        this.selectedValuesLocal = this.prevSelectedValuesState;
        this.prevSelectedValuesState = [];
      }
      this.isApproved = false;
    },
  },
  computed: {
    filteredList() {
      let list = this.list;
      if (this.search.length > 2) {
        list = list.filter((option) =>
          option.name.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      return list;
    },
  },
  methods: {
    userCheck() {
      this.$emit("user-interaction");
      this.setValue();
    },
    setValue() {
      this.$emit("setValue", this.selectedValuesLocal);
    },
    toggleModal() {
      this.isOpen = !this.isOpen;
    },
    closeModal() {
      this.isOpen = false;
    },
    selectAll() {
      this.selectedValuesLocal = this.list.map((item) => item.id);
      this.userCheck();
    },
    removeSelection() {
      this.selectedValuesLocal = [];
      this.userCheck();
    },
    searchValue(value) {
      this.search = value;
    },
    approve() {
      this.$emit("approve", this.selectedValuesLocal);
      this.isApproved = true;
      this.isOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.filter {
  position: relative;
  display: inline-block;
  min-width: 200px;
  text-align: left;
  outline: none;
  height: auto;
  user-select: none;
}

.filter--active {
  box-shadow: 0 0 0px 1px #1981c9;
}

.filter-title {
  padding: 7px 35px 7px 15px;
  font-weight: 700;
  cursor: pointer;
  border: 1px solid #cad0d4;
  background-color: #ffffff;
  border-radius: 2px;
  position: relative;
  display: flex;
  justify-content: space-between;
  column-gap: 30px;
}

.filter-title::after {
  position: absolute;
  content: "";
  top: 50%;
  right: 10px;
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-color: $erp-primary-bg-color transparent transparent transparent;
  opacity: 0.5;
}

.filter-title__active-count {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  color: #1981c9;
}

.filter-title__active-count:after {
  display: block;
  content: "";
  position: absolute;
  width: 23px;
  height: 23px;
  border: 1px solid #1981c9;
  border-radius: 50%;
}

.filter-body {
  padding-top: 12px;
  border-radius: 0px 0px 6px 6px;
  position: absolute;
  background-color: #ffffff;
  left: 0;
  right: 0;
  z-index: 30;
  box-shadow: 4px 8px 8px -4px rgba(34, 60, 80, 0.2);
  font-weight: 500;
}

.filter-body__scroll {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 305px;
}

.filter-body__item {
  padding: 5px 7px;
}

.filter-body__item--capitalize {
  text-transform: capitalize;
}

.filter-body__item--wrapper {
  display: flex;
  gap: 15px;
}

.filter-body__item-approve {
  margin-left: auto;
}

.filter-body__item-label {
  display: flex;
  align-items: baseline;
  gap: 7px;
  cursor: pointer;

  &:hover {
    color: #247096;
  }

  input {
    line-height: 1.6;
  }
}

.filter-body__search-wrapper {
  padding: 0 7px 5px 7px;
}
</style>
