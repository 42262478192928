<template>
  <modal
    :data="modalData"
    class="modal--vacation"
    ref="modal"
    v-click-outside="close"
  >
    <p>
      <label
        ><input type="radio" v-model="selectedType" value="WORKING" />
        Рабочий</label
      >
    </p>
    <p>
      <label
        ><input type="radio" v-model="selectedType" value="VACATION" />
        Отпуск</label
      >
    </p>
    <p>
      <label
        ><input type="radio" v-model="selectedType" value="SICK" />
        Больничный</label
      >
    </p>
  </modal>
</template>

<script>
import modal from "./ModalProto";

export default {
  name: "InfoModal",
  components: {
    modal,
  },
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      selectedType: "WORKING",
      modalData: {
        title: "Выберите тип дня",
        actions: [
          {
            id: "accept",
            title: `Выбрать`,
            classes: "modal-message__accept",
            handler: () => {
              const modal = this.$refs.modal;
              modal.close();
              this.selectType();
            },
          },
          {
            id: "cancel",
            title: `Отменить`,
            classes: "modal-message__cancel",
            handler: () => {
              const modal = this.$refs.modal;
              modal.close();
            },
          },
        ],
      },
    };
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    selectType() {
      this.$emit("selectDayType", this.selectedType);
    },
    setPosition(coords) {
      if (!coords) {
        return;
      }
      this.$refs.modal.setPosition(coords);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/modal.scss";
</style>
