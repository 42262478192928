<script>
export default {
  name: "VacationTableNavigation",
  functional: true,
  props: {
    months: {
      type: Array,
      default: () => [],
    },
    active: {
      type: String,
      default: "",
    },
    editedMonths: {
      type: Array,
      default: () => [],
    },
  },
  render(createElement, context) {
    const { props } = context;
    return createElement(
      "div",
      { class: ["table-nav"] },
      props.months.map((month, index) => {
        return createElement(
          "div",
          {
            class: {
              "table-nav__item": true,
              "table-nav__item--active": props.active === month.name,
              "table-nav__item--edited": props.editedMonths.includes(index),
            },
            key: month.name,
            on: {
              click: () => {
                context.listeners.click(month.name);
              },
            },
          },
          month.name
        );
      })
    );
  },
};
</script>
