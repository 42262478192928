<template>
  <div class="container">
    <h2>График отпусков</h2>
    <VacationIntervalSelect
      ref="interval-select"
      :initDate="initIntervalDate"
      :firstPlanningDate="planningDate.first"
      :lastPlanningDate="planningDate.last"
      :datesMap="intervalDatesMap"
      :modes="modesForInterval"
      @getInterval="getInterval"
      @changeMode="selectMode"
    />
    <div v-show="tableData.ok" class="vacation__filters">
      <label
        ><input type="radio" v-model="viewMode" value="general" /> все
        сотрудники</label
      >
      <label
        ><input type="radio" v-model="viewMode" value="projects-context" />
        группировка по проектам</label
      >
    </div>
    <div v-if="viewMode === 'projects-context'" class="filter-block">
      <CheckboxFilterModal
        title="Проекты"
        :list="getProjectsList"
        :selectedValues="projects.idsForSelected"
        :with-active-items-number="true"
        :with-approve-button="true"
        :with-search="true"
        class="filter-block__item"
        @approve="setFilter"
      />
    </div>
    <VacationTable
      v-if="yearID"
      :data="tableData"
      :loadingState="tableLoadingState"
      @changeDates="updateDatesType"
    />
    <div v-else class="empty-state">
      По {{ selectedYear }} году нет информации. Выберете доступный год:
      <ul class="empty-state__list">
        <li v-for="year of [...calendarYears].reverse()" :key="year.id">
          <router-link :to="`/vacation-schedule/${year.number}`"
            >{{ year.number }}
          </router-link>
        </li>
      </ul>
    </div>
    <div class="instruction">
      <h3>Инструкция по использованию</h3>
      <div class="instruction__text">
        <ul>
          <li v-if="myEditingRules">
            {{ myEditingRules }}
          </li>
          <li>
            Можно редактировать график отпусков прошлого, текущего и будущих
            месяцев. Дни позапрошлого и более ранних месяцев окрашены серым
            цветом и недоступны для редактирования;
          </li>
          <li>Для выбора одного дня нажмите на него левой кнопкой;</li>
          <li>
            Для выбора диапазона дней, проведите по ним курсором мыши с зажатой
            левой кнопкой;
          </li>
          <li>
            Для выбора нескольких дней или диапазонов дней, удерживайте клавишу
            Ctrl (Windows)/Cmd (MacOS) и выберите нужные дни мышью;
          </li>
          <li>
            Для редактирования типа дня/диапазона дней, нажмите правой кнопкой
            мыши на выбранные дни. В появившемся модальном окне выберите нужный
            тип и нажмите “Выбрать”.
          </li>
        </ul>
        <h4>Типы дней по цветам:</h4>
        <div class="day-types">
          <div
            v-for="type of dayTypes"
            :key="type.id"
            :style="`background-color: ${type.color}`"
            class="day-types__item"
          >
            {{ type.label }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { YearVacationTable } from "@/assets/dataStructs";
import { getDateFromPlanning, daysAYear, isNumber } from "@/assets/utils";
import { ROLES } from "@/assets/enums";
import VacationIntervalSelect from "@/components/VacationIntervalSelect";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import VacationTable from "@/components/VacationTable.vue";
import CheckboxFilterModal from "@/components/CheckboxFilterModal";

dayjs.extend(isoWeek);

export default {
  name: "VacationSchedulePage",
  components: {
    VacationIntervalSelect,
    CheckboxFilterModal,
    VacationTable,
  },
  data() {
    return {
      viewMode: "general",
      // * * * * *
      // viewMode
      // "general" - классическая таблица;
      // "projects-context" - сотрудники в контексте проектов
      dateSince: null,
      dateUntil: null,
      yearData: YearVacationTable.create(this.$route.params.year),
      dayTypes: [
        { id: "sick", label: "Больничный", color: "#80cfc8" },
        { id: "vacation", label: "Отпуск", color: "#a1d881" },
        { id: "dayoff", label: "Выходной/праздничный", color: "#ffba9b" },
        { id: "shortened", label: "Сокращенный", color: "#fdff8a" },
        { id: "selected", label: "Выбранный", color: "#c6dcfa" },
        { id: "working", label: "Рабочий", color: "#f5f5f5" },
      ],
      selectedYear: null,
      yearID: null,
      tableLoadingState: "loading",
      projects: {
        idsForSelected: [],
        prevProjectsCount: 0,
        isInit: true,
      },
      groups: [],
      employeesInProjects: {},
    };
  },
  watch: {
    "$route.params.year": {
      async handler(year) {
        this.selectedYear = parseInt(year);
        this.yearData = YearVacationTable.create(year);
      },
      immediate: true,
    },
    async viewMode(mode) {
      this.tableLoadingState = "loading";
      const intervalSelect = this.$refs["interval-select"] || {};

      if (mode === "general") {
        const currentMode = localStorage.getItem("_interval-mode-vacation");
        await intervalSelect.selectMode(currentMode || "year");
        await this.loadCalendar();
      }
      if (mode === "projects-context") {
        await intervalSelect.selectMode("month");
        await this.loadPlanningsList({ limit: 999 });
        const monthId = this.getMonthId(this.dateSince);
        await this.loadContextOfPtojects(monthId);
      }

      this.tableLoadingState = "completed";
    },
    selectedYear: {
      async handler(year) {
        if (parseInt(this.$route?.params["year"]) !== year) {
          this.$router.push({ path: `/vacation-schedule/${year}` });
        }
        try {
          this.tableLoadingState = "loading";

          await this.loadCalendarYears();
          this.yearID =
            this.getCalendarYears.find(
              (date) => date.number === this.selectedYear
            )?.id || null;
          if (this.yearID === null) {
            this.tableLoadingState = "completed";
            return;
          }
          const daysCount = daysAYear(year);
          await this.loadCalendarDays({
            year_id: this.yearID,
            limit: daysCount,
          });
          await this.loadCalendar();
          if (this.viewMode === "projects-context") {
            const monthId = this.getMonthId(this.dateSince);
            await this.loadContextOfPtojects(monthId);
          }
          this.tableLoadingState = "completed";
        } catch {
          console.error("Ошибка при загрузке");
        }
      },
      immediate: true,
    },
    getProjectsList() {
      this.projectsControl();
    },
    dateSince: {
      async handler(date) {
        if (this.viewMode === "projects-context" && this.planningsList.length) {
          this.tableLoadingState = "loading";
          const monthId = this.getMonthId(date);
          await this.loadContextOfPtojects(monthId);
          this.tableLoadingState = "completed";
        }
      },
      deep: true,
    },
    calendarWithProjects({ projects, activeIds }) {
      if (this.viewMode === "general") {
        return;
      }
      this.employeesInProjects = {};
      this.groups = projects
        .filter((project) => {
          return activeIds.includes(project.id);
        })
        .map((group) => {
          group.staff.map((employee) => {
            if (!this.employeesInProjects[employee.id]) {
              this.employeesInProjects[employee.id] = [];
            }
            this.employeesInProjects[employee.id].push(group.id);
            return employee;
          });
          group.calendar = this.yearData;
          return group;
        });
    },
    async yearID(id) {
      if (
        this.me &&
        this.me.groups.includes(ROLES.PM) &&
        !this.me.groups.includes(ROLES.FOUNDER) &&
        !this.me.groups.includes(ROLES.PMO)
      ) {
        await this.loadListForEditing({
          year_id: id,
          limit: 999,
        });
      }
    },
    currentDaysCalendar(calendar) {
      this.yearData.initCalendar(calendar); // TODO: название yearData нужно поменять. Это же наша созданная структура. Почему это yearData.
    },
    myCalendar(employees) {
      if (!employees) {
        return;
      }
      employees.forEach((employee) => {
        this.yearData.initEmployee(employee.id, employee.days);
        this.yearData.setMonthsAvailabilityForEmployee(
          employee.id,
          (employeeId, month) => {
            return (
              this.isThereAccess(employeeId, month.id) &&
              dayjs(`${month.yearNumber}-${month.number}`) >
                this.getMyDate.subtract(2, "months")
            );
          }
        );
      });
      if (this.viewMode === "general") {
        this.groups = [
          {
            name: "common",
            id: "common",
            staff: employees,
          },
        ];
      }
    },
    interval({ sinceMonth, untilMonth }) {
      this.yearData.setDisplayedMonths(sinceMonth - 1, untilMonth);
    },
  },
  computed: {
    ...mapState({
      planningsList: (state) => state.planningsStore.planningsList,
      myCalendar: (state) => state.employeeCalendarStore.myCalendar,
      listForEditing: (state) => state.employeeCalendarStore.listForEditing,
      currentDaysCalendar: (state) =>
        state.productionCalendarStore.currentDaysCalendar,
      calendarYears: (state) => state.productionCalendarStore.calendarYears,
      me: (state) => state.userStore.me,
    }),
    ...mapGetters({
      getMyDate: "userStore/getMyDate",
      getProjectsList: "employeeCalendarStore/getProjectsList",
      getEmployeesInProjects: "employeeCalendarStore/getEmployeesInProjects",
      getCalendarHash: "productionCalendarStore/getCalendarHash",
      getCalendarYears: "productionCalendarStore/getCalendarYears",
    }),
    calendarWithProjects() {
      return {
        projects: this.getEmployeesInProjects,
        activeIds: this.projects.idsForSelected,
      };
    },
    interval() {
      const [year, sinceMonth] = this.getDatePath(this.dateSince || "");
      const [_untilYear, untilMonth] = this.getDatePath(this.dateUntil || "");
      return {
        sinceMonth,
        untilMonth,
        year,
      };
    },
    modesForInterval() {
      if (this.viewMode === "general") {
        return [
          { label: "Год", id: "year" },
          { label: "Квартал", id: "quarter" },
        ];
      } else {
        return [{ label: "Месяц", id: "month" }];
      }
    },
    intervalDatesMap() {
      if (this.viewMode === "general" || !this.planningsList.length) {
        return [];
      }
      return this.planningsList.reduce(
        (state, currentItem, index) => {
          const currentDate = getDateFromPlanning(currentItem);
          let step = null;
          if (state.prevKey) {
            step = dayjs(state.prevKey).diff(currentDate, "month");
            state.map[index - 1][1].prev = step;
          }
          state.map.push([currentDate, { prev: null, next: step }]);
          if (index + 1 === this.planningsList.length) {
            return state.map;
          } else {
            state.prevKey = currentDate;
            return state;
          }
        },
        { map: [], prevKey: null }
      );
    },
    myEditingRules() {
      if (!this.me) {
        return "";
      }
      if (
        this.me.groups.includes(ROLES.FOUNDER) ||
        this.me.groups.includes(ROLES.PMO)
      ) {
        return "Вы можете редактировать свой график отпусков и графики отпусков всех сотрудников;";
      }
      if (this.me.groups.includes(ROLES.PM)) {
        return "Вы можете редактировать свой график отпусков и графики отпусков Вашей команды;";
      }
      return "Вы можете редактировать только свой график отпусков;";
    },
    initIntervalDate() {
      if (!this.getMyDate) {
        return null;
      }
      if (this.getMyDate.year() === this.selectedYear) {
        return this.getMyDate;
      }
      return dayjs(`${this.selectedYear}-1-1`);
    },
    planningDate() {
      if (this.viewMode === "general") {
        return {
          first: this.getCalendarYears.length
            ? this.getCalendarYears[
                this.getCalendarYears.length - 1
              ]?.number.toString()
            : dayjs().year().toString(),
          last: this.getCalendarYears.length
            ? dayjs(`${this.getCalendarYears[0]?.number}`)
                .endOf("year")
                .format("YYYY-MM")
            : dayjs().endOf("year").format("YYYY-MM"),
        };
      } else {
        if (!this.intervalDatesMap.length) {
          return {};
        }
        const [earliestDate] = this.intervalDatesMap.at(-1);
        const [endDate] = this.intervalDatesMap[0];
        const first = dayjs(earliestDate).startOf("month").format("YYYY-MM");
        const last = dayjs(endDate).endOf("month").format("YYYY-MM");
        return { first, last };
      }
    },
    tableData() {
      const { sinceMonth, untilMonth, year } = this.interval;

      if (!this.groups || !sinceMonth) {
        return { ok: false };
      }

      const currentMonthIndex = this.getMyDate.month();
      const currentYear = this.getMyDate.year();
      let currentMonthId = null; // TODO: не null а 0, я полагаю

      if (
        currentYear === year &&
        sinceMonth !== untilMonth &&
        sinceMonth <= currentMonthIndex + 1 &&
        currentMonthIndex + 1 <= untilMonth
      ) {
        currentMonthId = this.yearData.getMonthNameByIndex(currentMonthIndex);
      } else {
        currentMonthId = this.yearData.getMonthNameByIndex(sinceMonth - 1);
      }

      return {
        ok: true,
        type: this.viewMode === "general" ? "common" : "context",
        calendar: this.yearData,
        groups: this.groups,
        currentMonthId,
      };
    },
  },
  methods: {
    ...mapActions({
      loadMyCalendar: "employeeCalendarStore/loadMyCalendar",
      loadContextOfPtojects: "employeeCalendarStore/loadContextOfPtojects",
      updateCalendar: "employeeCalendarStore/updateCalendar",
      loadListForEditing: "employeeCalendarStore/loadListForEditing",
      loadCalendarMonths: "productionCalendarStore/loadCalendarMonths",
      loadCalendarYears: "productionCalendarStore/loadCalendarYears",
      loadPlanningsList: "planningsStore/loadPlanningsList",
      loadCalendarDays: "productionCalendarStore/loadCalendarDays",
    }),
    getDatePath(path) {
      return path.split("-").map((x) => parseInt(x, 10));
    },
    isThereAccess(employeeId, monthId) {
      if (!this.me || !isNumber(employeeId)) {
        return false;
      }
      const { groups, id: myId } = this.me;
      const currentMonth = this.listForEditing.find(
        (month) => month.id === monthId
      );
      return (
        groups.includes(ROLES.FOUNDER) ||
        groups.includes(ROLES.PMO) ||
        myId === employeeId ||
        (groups.includes(ROLES.PM) &&
          currentMonth &&
          currentMonth.profile_ids.includes(employeeId))
      );
    },
    getMonthId(date) {
      const [year, month] = this.getDatePath(date);
      return (
        this.planningsList.find(
          (planningDate) =>
            planningDate.name === month && planningDate.year.number === year
        ) || {}
      ).id;
    },
    async loadCalendar() {
      try {
        await this.loadMyCalendar({
          year_id: this.yearID,
          limit: 999,
        });
      } catch (e) {
        console.error(e);
      }
    },
    getInterval(interval) {
      this.dateSince = interval[0].since;
      this.dateUntil = interval[0].until;
      const [intervalYear] = this.getDatePath(this.dateSince);
      if (this.selectedYear && this.selectedYear !== intervalYear) {
        this.selectedYear = intervalYear;
      }
    },
    async updateDatesType(dates, type, employeeId) {
      this.tableLoadingState = "update";
      const requests = [];
      for (const interval of dates) {
        requests.push(
          this.updateCalendar({
            day_type: type,
            ids: interval.map(([dateId]) => dateId),
            profile_id: employeeId,
          })
        );
      }

      await Promise.all(requests)
        .then((responses) => {
          const dateTransformer = (date) => {
            return date.split("::").map((x) => parseInt(x));
          };
          responses.forEach((response, index) => {
            if (response.ok) {
              this.yearData.modifyIntervalType(
                dates[index],
                type,
                dateTransformer
              );
            } else {
              switch (response.details.code) {
                case 403:
                  this.$pushMessage({
                    message:
                      "Вы не можете редактировать график отпуска сотрудника в этом периоде.",
                    location: "updateDatesType",
                    title: "Изменение типа дней",
                    type: "error",
                  });
                  break;
                default:
                  this.$pushMessage({
                    message:
                      "Запрос на сервер закончился неудачей. Проверьте своё подключение к интернету или попробуйте позже.",
                    location: "updateDatesType",
                    title: "Изменение типа дней",
                    type: "error",
                  });
                  console.error("Ошибка сети");
                  break;
              }
            }
          });
        })
        .catch((error) => {
          console.error(error);
        });

      this.tableLoadingState = "completed";
    },
    selectMode(mode, prevMode) {
      if (mode !== prevMode && mode === "month") {
        this.projectsControl("reset");
      }
    },
    projectsControl(action) {
      const currentProjectsIds = this.getProjectsList.map(
        (project) => project.id
      );
      const projectsCount = this.getProjectsList.length;
      if (this.projects.isInit) {
        // первый запуск
        this.projects.isInit = false;
        this.projects.idsForSelected = currentProjectsIds;
        this.projects.prevProjectsCount = projectsCount;
        return;
      }
      const availableSelectedProjects = this.projects.idsForSelected.filter(
        (id) => !!this.getProjectsList.find((project) => project.id === id)
      );
      if (
        !this.projects.idsForSelected.length || // если нет выбранных проектов
        (!availableSelectedProjects.length &&
          this.projects.prevProjectsCount) || // проект был выбран, но его нет в текущем месяце
        this.projects.prevProjectsCount === this.projects.idsForSelected.length // если выбраны все проекты
      ) {
        this.projects.idsForSelected = currentProjectsIds;
        this.projects.prevProjectsCount = projectsCount;
        return;
      }
      if (action === "reset") {
        this.projects.idsForSelected = [];
        this.projects.prevProjectsCount = 0;
        this.projects.isInit = true;
        return;
      }
      this.projects.idsForSelected = availableSelectedProjects;
      this.projects.prevProjectsCount = projectsCount;
    },
    setFilter(projectsIds) {
      this.projects.idsForSelected = projectsIds;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables";

h4 {
  margin-top: 30px;
}

.vacation__filters {
  display: flex;
  gap: 40px;
  padding: 30px 0;
}

.day-types {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.day-types__item {
  border-radius: 7px;
  border: 2px solid #616161;
  padding: 4px 7px;
}

.instruction {
  margin-top: 40px;
}

.instruction__text {
  margin-top: 20px;
}

.empty-state__list {
  & a {
    color: $erp-action-color;
  }
}

.filter-block {
  margin-top: 25px;
  margin-bottom: 25px;
}

.filter-block__item {
  width: 300px;
}
</style>
